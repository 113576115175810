<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
        <el-tooltip
          class="item"
          effect="dark"
          content="增加条款协议"
          placement="top-start"
        >
          <el-button type="primary" @click="addAreaBtn">增加条款协议</el-button>
        </el-tooltip>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
        <el-table-column align="center" prop="content" label="协议类型">
          <template slot-scope="scope">
            <div class="block">
              <div class="hdContent" v-if="scope.row.advertisement_type == 1">
                条款协议
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="advertisement_title"
          label="协议标题"
        >
        </el-table-column>
        <el-table-column align="center" prop="content" label="协议内容">
          <template slot-scope="scope">
            <div class="block">
              <div class="hdContent">{{ scope.row.advertisement_content }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <div class="block">
              <el-switch
                @change="change(scope.row)"
                v-model="scope.row.advertisement_status"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
              <!-- <el-tag type="success" v-if="scope.row.status">显示</el-tag>
                <el-tag type="success" v-else>隐藏</el-tag> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看活动协议"
              placement="top-start"
            >
              <el-button
                icon="el-icon-search"
                @click="lookPicture(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改活动协议"
              placement="top-start"
            >
              <el-button
                icon="el-icon-edit"
                type="primary"
                @click="updataPicture(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除活动协议"
              placement="top-start"
            >
              <el-button
                icon="el-icon-delete"
                type="danger"
                @click="delPicture(scope.row.advertisement_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>

    <!-- 增加场地服务标题 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <div slot="title" v-if="updataFalg" class="dialog-footer">
        <div class="title">修改活动协议</div>
      </div>
      <div slot="title" v-else-if="lookFalg" class="dialog-footer">
        <div class="title">查看活动协议</div>
      </div>
      <div slot="title" v-else class="dialog-footer">
        <div class="title">增加活动协议</div>
      </div>
      <div class="diaBox">
        <el-form :model="form" ref="ruleForm" :rules="rules">
          <el-form-item
            label="活动协议内容"
            :label-width="formLabelWidth"
            prop="content"
          >
            <el-input
              type="textarea"
              :rows="10"
              v-model="form.content"
              placeholder="请输入活动协议内容"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
            <el-input
              v-model="form.sort"
              placeholder="请输入排序"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="状态"
            :label-width="formLabelWidth"
            v-if="updataFalg || lookFalg"
          >
            <template>
              <el-switch
                v-model="form.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </template>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="addFlag" type="primary" @click="addArea('ruleForm')"
          >添 加</el-button
        >
        <el-button
          v-else-if="lookFalg"
          type="primary"
          @click="dialogFormVisible = false"
          >确 认</el-button
        >
        <el-button v-else type="primary" @click="updSiteSer('ruleForm')"
          >修 改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  agreementList,
  agreementAdd,
  agreementDel,
  agreementEdit,
  agreementInfo,
} from "@/api/site/agreementList.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    var validateContent = (rule, value, callback) => {
      if (this.form.content === "") {
        callback(new Error("请输入活动协议"));
      } else {
        callback();
      }
    };
    var validateSort = (rule, value, callback) => {
      if (this.form.sort === "") {
        callback(new Error("请输入排序"));
      } else {
        callback();
      }
    };
    //这里存放数据
    return {
      rules: {
        content: [{ validator: validateContent, trigger: "blur" }],
        sort: [{ validator: validateSort, trigger: "blur" }],
      },
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      form: {
        content: "", //内容
        sort: "", //排序
        status: 1,
      },
      props: {
        children: "sub",
        value: "id",
        label: "name",
      },
      //表格数据
      tableData: [],
      token: {
        token: window.sessionStorage.getItem("token"),
      },
      formLabelWidth: "100px",
      imgParam: {
        path: "banner",
      },
      fileList: [],
      //控制弹窗--新增修改
      dialogFormVisible: false,
      addFlag: false,
      lookFalg: false,
      updataFalg: false,
    };
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {
    dialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.form = this.$options.data().form;
        this.updataFalg = false;
        this.lookFalg = false;
        this.addFlag = false;
      }
    },
  },
  //方法集合
  methods: {
    //查看场地服务详情
    lookPicture(item) {
      this.$router.push({
        name: "agreementListAdd",
        params: {
          content: item.content,
          type: 0,
        },
      });
    },
    //修改合同模板btn
    updataPicture(item) {
      console.log(item);
      this.$router.push({
        name: "agreementListAdd",
        params: {
          type: 1,
          advertisement_title: item.advertisement_title,
          content: item.advertisement_content,
          advertisement_type: 1,
          id: item.advertisement_id,
          sort: item.advertisement_sort,
        },
      });
    },
    change(item) {
      var data = {
        type: 1,
        advertisement_title: item.advertisement_title,
        advertisement_content: item.advertisement_content,
        advertisement_type: 1,
        advertisement_id: item.advertisement_id,
        advertisement_status: item.advertisement_status ? 1 : 0,
      };
      this.agreementEdit(data);
    },
    //修改合同模板模态框
    updSiteSer(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form);
          this.agreementEdit(this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //删除合同模板
    delPicture(advertisement_id) {
      var data = {
        advertisement_id: advertisement_id,
      };
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(data);
          this.agreementDel(data);
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //增加场地服务
    addArea(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form);
          this.agreementAdd(this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //增加区域按钮
    addAreaBtn() {
      this.$router.push({
        name: "agreementListAdd",
        params: {
          type: 2,
        },
      });
      //      this.dialogFormVisible=true;
      //   this.addFlag=true;
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      this.SpaceList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      this.SpaceList(data);
    },
    exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      // console.log(response)
      this.form.thumb = response.data.url;
    },

    //移除图片的事件
    imgRemove(file, fileList) {
      this.form.thumb = "";
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        space_id: "", //场地id
        name: "", //区域名称
        thumb: "", //图片
        desc: "", //描述
        type: "", //空间区域0室内  1室外
        rec: "", //0否 1是
      };
      (this.fileList = []), console.log("close");
    },
    /**-----------------网络请求生命周期函数--------------------- */
    //活动协议列表
    async agreementList(data) {
      let res = await agreementList(data);
      res.data.result.data.forEach((v) => {
        v.advertisement_status = v.advertisement_status ? true : false;
      });
      this.tableData = res.data.result.data;
      this.dialogFormVisible = false;
      this.exp.num = res.data.result.total;
    },
    //活动协议增加
    async agreementAdd(datas) {
      let res = await agreementAdd(datas);
      if (res.data.status == 1) {
        this.$message({
          message: res.data.message,
          type: "success",
        });
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        this.agreementList(data);
      } else {
        this.$message({
          message: res.data.message,
          type: "error",
        });
      }
    },
    //活动协议删除
    async agreementDel(datas) {
      let res = await agreementDel(datas);
      if (res.data.status == 1) {
        this.$message({
          message: res.data.message,
          type: "success",
        });
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        this.agreementList(data);
      } else {
        this.$message({
          message: res.data.message,
          type: "error",
        });
      }
    },
    //活动协议修改
    async agreementEdit(datas) {
      console.log(datas);
      let res = await agreementEdit(datas);
      if (res.data.status == 1) {
        this.$message({
          message: res.data.message,
          type: "success",
        });
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        this.agreementList(data);
      } else {
        this.$message({
          message: res.data.message,
          type: "error",
        });
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data = {
      page: this.getInfo.page_code,
      list_rows: this.getInfo.page_num,
    };
    this.agreementList(data);
  },

  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>
  
  <style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox {
  display: flex;
  justify-content: center;
}
.imgUrl {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 250px;
  height: 150px;
  overflow: hidden;
  text-align: center;
}
.hdContent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>